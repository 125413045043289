import React, { useState } from 'react'
import axios from 'axios'
import cn from 'classnames'
import scrollTo from 'animated-scroll-to'

const App = () => {
  const [barcode, setBarcode] = useState('')
  const [lastBarcode, setLastBarcode] = useState('')
  const [results, setResults] = useState([])
  const [error, setError] = useState('')
  const [debt, setDebt] = useState(0)

  const getResults = (e) => {
    if (e) e.preventDefault()
    // noinspection JSUnusedGlobalSymbols
    scrollTo(0, {
      element: document.getElementById('root'),
      minDuration: 480,
      onComplete: () => {
        setResults([])
        setError('')
        setDebt(0)

        axios.post('/results', { barcode })
          .then(({ data: results }) => {
            if (results.length === 0) {
              return setError('Результаты не найдены')
            }

            setResults(results)

            scrollTo(window.innerHeight - 80, {
              element: document.getElementById('root'),
              minDuration: 480
            })
          })
          .catch(({ response }) => {
            setLastBarcode(barcode)
            switch (response.status) {
              case 500: return setError('Произошла непредвиденная ошибка. Пожалуйста, повторите попытку позже')
              case 402:
                return setDebt(response.data.debt)
            }
          })
      }
    })
  }

  return <>
    <div id="search-form">
      <div className='content'>
        <div className='container'>
          <h3 className='head_title'>Результаты исследований</h3>
          <p className='head_subtitle'>Укажите баркод из бланка заказа</p>

          <form onSubmit={getResults}>
            <div id="custom-search-input">
              <div className='input-group'>
                <input
                  className='search-query'
                  name='barcode'
                  type='number'
                  value={barcode}
                  onChange={(e) => setBarcode(e.target.value)}
                  placeholder='От 20 до 30 цифр' />

                <input
                  className='btn_search'
                  type='submit'
                  value='Search' />
              </div>
            </div>
          </form>

          {error && <div className='error-message'>{ error }</div>}
          {debt > 0 && <div className='error-message debt'>
            <p>
              <span>Задолженность по заказу </span>
              <b>{ lastBarcode }</b>
              <span> составляет </span>
              <b>{ debt }</b>
              <span> сум.</span>
            </p>
            <p>Задолженность можно погасить в кассе научно-диагностического центра «IMMUNOGEN TEST» по адресу:</p>
            <p>
              <a
                href='https://maps.google.com/?q=Узбекистан, г. Ташкент, 100060, улица Я. Гулямова, 74'
                rel="noopener noreferrer"
                target='_blank'
              >
                <span>Узбекистан, г. Ташкент, 100060,</span>
                <br />
                <span>улица Я. Гулямова, 74</span>
              </a>
            </p>
            <p>Телефоны для справок:</p>
            <p className='shift'><a href="tel:998712070814">+998 (71) 207-08-14</a></p>
          </div>}
        </div>
      </div>
    </div>

    { results.length > 0 && <div id='search-results'>
      <div className='content'>
        <ul className='results_list'>
          { results.map((item) => <a
            key={item.analysis_code}
            rel="noopener noreferrer"
            target="_blank"
            aria-disabled={!item.analysis_check_result}
            href={`/report/?type=pdf&pacient_barcode=${item.pacient_barcode}&analysis_code=${item.analysis_code}`}>
            <li className='result_item'>
              <div className={cn('status', { 'not-ready': !item.analysis_check_result })}><div /></div>
              <div className='result_name'>{ item.analysis_name }</div>
            </li>
          </a>) }
        </ul>

        <div className='reference'>
          <h3>Обозначения</h3>
          <p>
            <div className='status ready'><div /></div>
            <span>&nbsp;— результаты анализов готовы. При нажатии откроется результат в формате PDF</span>
          </p>
          <p>
            <div className='status not-ready'><div /></div>
            <span>&nbsp;— результаты анализов еще не готовы</span>
          </p>
        </div>
      </div>
    </div> }
  </>
}

export default App
